import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Footer from "../components/footer";
import Metatags from "../components/metatags";
import HeroStyles from "../components/blank-canvas-anxiety/hero.module.scss";
import Section from "../components/blank-canvas-anxiety/section.module.scss";
import Selection from "../components/blank-canvas-anxiety/selection.module.scss";
import Sketch from "../components/blank-canvas-anxiety/sketch.module.scss";
import Diagram from "../components/blank-canvas-anxiety/diagram.module.scss";
import SelectionTags from "../components/blank-canvas-anxiety/handles";
import Hero from "../images/blank-canvas-anxiety/rectangle.png";
import Artboards from "../videos/blank-canvas-anxiety/artboards.mp4";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <Metatags title="Blank Canvas Anxiety | May 26 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-26.png" url="https://cssgrid31.brett.cool" pathname="/may-26" mdxType="Metatags" />
    <div className={HeroStyles.canvas}>
  <div className={HeroStyles.lock} />
  <div className={HeroStyles.stroke_v} />
  <div className={HeroStyles.stroke_v2} />
  <div className={HeroStyles.stroke_h} />
  <h1 className={Selection.box}>
    Blank
    <br />
    Canvas
    <br />
    Anxiety
    <SelectionTags mdxType="SelectionTags" />
  </h1>
  <div className={HeroStyles.rectangles}>
    <img src={Hero} alt="Dragging out a rectangle in Sketch" width="585" />
  </div>
  <div className={HeroStyles.video}>
    <video className={HeroStyles.artboardVideo} src={Artboards} autoPlay={true} loop={true} />
  </div>
  <div className={`${HeroStyles.subtitle} ${Selection.box}`}>
    <p>A blank document illuminates my screen. I tap the <kbd>A</kbd> key, and draw an artboard…</p>
    <SelectionTags mdxType="SelectionTags" />
  </div>
    </div>
    <div className={`${Section.container} ${Section.altbg}`}>
      <div className={Section.questionsCopy}>
        <h2>The early stages</h2>
        <p>{`Questions begin to emerge, as I start setting up my document.`}</p>
        <p>{`I draw out rectangles, set text, pick colours, toggle grids, rulers, and guides on, off, and on again.`}</p>
        <p>{`I place and move elements—in 1 and 10 pixel increments.`}</p>
        <p>{`Sometimes, it comes together quickly. But sometimes, the questions continue…`}</p>
      </div>
      <div className={Section.questions}>
  <div className={Sketch.collection}>
    <div className={Sketch.box}>What dimensions should I make this artboard?</div>
    <div className={Sketch.box}>What base spacing size should I use?</div>
    <div className={Sketch.box}>How wide should the container be?</div>
    <div className={Sketch.box}>How many columns?</div>
    <div className={Sketch.box}>Should I align this to the content or padding pox?</div>
    <div className={Sketch.box}>Should I include a ½ sized outer gutter on container?</div>
    <div className={Sketch.box}>How do I best word this?</div>
    <div className={Sketch.box}>What icon can I draw to best illustrate this concept?</div>
    <div className={Sketch.box}>Is this layout working?</div>
    <div className={Sketch.box}>Will this ever look right?</div>
    <div className={Sketch.boxHighlighted}>Am I even any good at this?</div>
  </div>
      </div>
    </div>
    <div className={`${Section.container} ${Section.darkbg}`}>
      <div className={Section.murkyMiddle}>
  <div className={Diagram.container}>
    <div className={Diagram.atom}>
      <div className={Diagram.atomLabel}>Where I am</div>
    </div>
    <div className={Diagram.atomEnd}>
      <div className={Diagram.atomLabel}>Where I want to be</div>
    </div>
  </div>
      </div>
      <div className={Section.murkyMiddleCopy}>
        <h2>The murky middle process</h2>
        <p>{`With visual design, compositions have a way of not looking `}<em parentName="p">{`quite right`}</em>{` until all the pieces fall in to place.`}</p>
        <p>{`While there are principles that can guide your decision making, it can be demoralising while in the midst of a project, still moving from where you are with a design, to where you want to be.`}</p>
        <p>{`The most important thing to do at this point can be difficult, but it is simple.`}</p>
      </div>
    </div>
    <div className={`${Section.container} ${Section.blackbg}`}>
      <div className={Section.Go}>
        <h2><span style={{
            fontWeight: '100'
          }}>Keep going</span><br />
          <span style={{
            fontWeight: '200'
          }}>Keep going</span><br />
          <span style={{
            fontWeight: '300'
          }}>Keep going</span><br />
          <span style={{
            fontWeight: '400'
          }}>Keep going</span><br />
          <span style={{
            fontWeight: '500'
          }}>Keep going</span><br />
          <span style={{
            fontWeight: '600'
          }}>Keep going</span><br />
          <span style={{
            fontWeight: '700'
          }}>Keep going</span><br />
          <span style={{
            fontWeight: '800'
          }}>Keep going</span><br />
          <span style={{
            fontWeight: '900'
          }}>Keep going</span></h2>
      </div>
    </div>
    <div className={`${Section.container} ${Section.altbg}`}>
      <div className={Section.summaryHeading}><h2>Timebox. Practice. Present. Receive feedback. Repeat.</h2></div>
      <div className={Section.summaryBody}>
        <p>{`For me, it seems there are no shortcuts. No easy way out. It’s a numbers game. It all comes down to the hours you put in.`}</p>
        <p>{`If you’re like me, arbitrary deadlines are actually a very important tool. Don’t let side projects languish—finish them. Release them.`}</p>
        <p>{`And `}<mark>{`don’t let taste and perfectionism paralyse`}</mark>{` you.`}</p>
        <p>{`Something you finish that isn’t perfect is better than something perfect that you never finish.`}</p>
      </div>
    </div>
    <Footer date={26} prev={true} next={true} mdxType="Footer">
      <p>{`Getting a bit meta with this particular entry, and doing my best to follow my own advice.`}</p>
      <p>{`I had some ideas for this entry, but I seemed to face both writer’s block and whatever the design equivalent is.`}</p>
      <p>{`Nevertheless, one of my favourite things about this CSS Grid challenge I’ve set myself is it constrains me to just get things done, and focuses me in a way that I `}<em parentName="p">{`get something done`}</em>{` and release it.`}</p>
      <p>{`This kind of idea could easily languish for months more, without me ever getting around to finishing it.`}</p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      